<template>
  <Layout>
    <section class="csms-account">

      <div class="row justify-content-center mt-5">
        <div class="col-xl-5">
          <div class="text-center mb-5">
            <h4 class="text-primary">{{ $t('account.profile') }}</h4>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-4">
          <div class="card pricing-box">
            <div class="card-body p-4">

              <form action="#">

                <div class="mb-3">
                  <label>{{ $t('account.id') }}</label>
                  <input type="text" class="form-control" v-model="form.email" disabled />
                </div>

                <div class="mb-3">
                  <label>{{ $t('account.name') }}</label>
                  <input type="text"
                         class="form-control"
                         v-model="form.name"
                         :disabled="mode === 'VIEW'"
                         :class="{ 'is-invalid': submitted && $v.form.name.$error }" />
                  <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                    <span v-if="!$v.form.name.required">{{ $t('validation.nameIsRequired') }}</span>
                  </div>
                </div>
                <div class="mb-3">
                  <label>{{ $t('account.tel') }}</label>
                  <div>
                    <input type="text"
                           class="form-control"
                           v-model="form.mobile"
                           :disabled="mode === 'VIEW'"
                           :class="{ 'is-invalid': submitted && $v.form.mobile.$error }" />
                    <div v-if="submitted && $v.form.mobile.$error" class="invalid-feedback">
                      <span v-if="!$v.form.mobile.required">{{ $t('validation.telIsRequired') }}</span>
                    </div>
                  </div>
                </div>

              </form>

              <hr class="my-4 text-muted" />

              <div class="row">
                <div class="col-lg-6 text-start">
                  <button type="button" class="btn btn-outline-primary w-sm" @click="editPwd()">
                    {{ $t('btn.pwdChange') }}
                  </button>
                </div>
                <div class="col-lg-6 text-end">
                  <button type="button" class="btn btn-warning w-sm" v-if="mode === 'VIEW'"
                          @click="edit('EDIT')">{{ $t('btn.edit') }}</button>
                  <button type="button" class="btn btn-secondary w-sm me-3" v-if="mode === 'EDIT'"
                          @click="edit('VIEW')">{{ $t('btn.cancel') }}</button>
                  <button type="button" class="btn btn-success w-sm" v-if="mode === 'EDIT'"
                          @click="save()">{{ $t('btn.save') }}</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <edit-modal ref="profileEditModal" @callback="getItem" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import { required } from 'vuelidate/lib/validators';
import EditModal from '@/views/pages/account/ProfileEditModal.vue';

export default {
  components: { Layout, EditModal },
  data() {
    return {
      mode: 'VIEW',
      submitted: false,
      userId: null,
      form: {
        email: null,
        name: null,
        mobile: null
      },
      item: null
    }
  },
  validations: {
    form: {
      name: { required },
      mobile: { required }
    }
  },
  created() {
    const vm = this;

    vm.userId = this.$_app.user.sub;
  },
  mounted() {
    const vm = this;

    vm.getItem();
  },
  methods: {
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/Account/retrieve/${vm.userId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);
      }).catch((err) => {
        console.error('Account Retrieve Error !!', err)
      });
    },
    editPwd() {
      const vm = this;
      vm.edit('VIEW');

      vm.$nextTick(() => {
        this.$refs.profileEditModal.show();
      });
    },
    edit(type) {
      const vm = this;
      vm.mode = type;

      if (vm.mode === 'VIEW') {
        vm.getItem();
      }
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/Account/updateByMe';

      let _form = {
        id: vm.userId,
        name: vm.form.name,
        mobile: vm.form.mobile
      };

      http.patch(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.edit('VIEW');
        }
      }).catch(err => {
        console.error('Account Edit Error :: ', err)
      });
    }
  }
}
</script>
