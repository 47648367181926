<template>
  <b-modal header-class="ion-bg-modal-header" size="md"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('common.password') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="profile-update-old-pwd">{{ $t('common.oldPwd') }}</label>
            <b-form-input class="form-control"
                          type="password"
                          id="profile-update-old-pwd"
                          v-model="form.oldPwd"
                          :class="{ 'is-invalid': submitted && $v.form.oldPwd.$error }" />
            <div v-if="submitted && $v.form.oldPwd.$error" class="invalid-feedback">
              <span v-if="!$v.form.oldPwd.required">{{ $t('validation.pwdIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="profile-update-new-pwd">{{ $t('common.newPwd') }}</label>
            <b-form-input class="form-control"
                          type="password"
                          id="profile-update-new-pwd"
                          v-model="form.newPwd"
                          :class="{ 'is-invalid': submitted && $v.form.newPwd.$error }" />
            <div v-if="submitted && $v.form.newPwd.$error" class="invalid-feedback">
              <span v-if="!$v.form.newPwd.required">{{ $t('validation.pwdIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <label class="form-label" for="profile-update-new-pwd-check">{{ $t('common.newPwdCheck') }}</label>
            <b-form-input class="form-control"
                          type="password"
                          id="profile-update-new-pwd-check"
                          v-model="form.newPwdCheck"
                          :class="{ 'is-invalid': submitted && $v.form.newPwdCheck.$error }" />
            <div v-if="submitted && $v.form.newPwdCheck.$error" class="invalid-feedback">
              <span v-if="!$v.form.newPwdCheck.required">{{ $t('validation.pwdIsRequired') }}</span>
              <span v-else-if="!$v.form.newPwdCheck.sameAs">{{ $t('validation.newPwdCheckPlease') }}</span>
            </div>
          </div>
        </div>

        <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      visible: false,
      submitted: false,
      form: {
        oldPwd: null,
        newPwd: null,
        newPwdCheck: null
      },
      isAuthError: false,
      authError: null
    }
  },
  validations: {
    form: {
      oldPwd: { required },
      newPwd: { required },
      newPwdCheck: { required, sameAsPassword: sameAs('newPwd') }
    }
  },
  methods: {
    show() {
      const vm = this;
      vm.visible = true;
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        oldPwd: null,
        newPwd: null,
        newPwdCheck: null
      };

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/auth/Password/update';

      let _form = {
        oldPassword: vm.form.oldPwd,
        newPassword: vm.form.newPwd,
        confirmPassword: vm.form.newPwdCheck
      };

      http.post(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Password Edit Error :: ', err)

        vm.isAuthError = true;
        vm.authError = i18n.t('validation.oldPwdCheckPlease');
      });
    }
  }
}
</script>
